import React from "react";
import './Header.css';
import MainLogo from './assets/img/M-L01.png';
import { Link } from 'react-router-dom';
import BackgroundMusic from "./BackgroundMusic";  

const Header = () => {
return(
<div className="Header"> 
    <div className="Container-Header">
    <img src={MainLogo} alt="Inicio" className="logo" />
    <button><Link to="/" className="Linkto" style={{ display: 'block', width: '100%', height: '100%' }}><span>Inicio</span></Link></button>
    <button>   <Link to="/servicios" className="Linkto" style={{ display: 'block', width: '100%', height: '100%' }}><span>Servicios</span></Link></button>
    <button>     <Link to="/experiencia" className="Linkto" style={{ display: 'block', width: '100%', height: '100%' }}><span>Experiencia</span></Link></button> 
    <button>    <Link to="/contacto" className="Linkto" style={{ display: 'block', width: '100%', height: '100%' }}><span>Contacto</span></Link></button> 
        </div> 

</div>
)
}

export default Header;
