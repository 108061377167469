import React from "react";
import './Login.css';

function Login() {
  return (
    <main>
        <div className="login-Container">
            <div className="login-Title-Wrapper">
                <h2>Certificaciones de Equipos</h2>
               
            </div>
            <div className="Main-Login-Container">
                <h2>Iniciar Sesión</h2>
                <form method="post">
                    <div className="username">
                        <input type="text" required/>
                        <label>Nombre de Usuario</label>
                    </div>
                    <div className="password">
                        <input type="password" required/>
                        <label>Contraseña</label>
                    </div>
                    <div className="forgot">¿Has olvidado tu contraseña?</div>
                    <button className="submit-login" type="submit">Enviar</button>
                </form>

            </div>
        </div>
    </main>
  );
}

export default Login;
