import React, { useState } from "react";
import './PruebasElectricas.css';
import ContainerSlider from "./ContainerSlider";

import HPT002 from './assets/img/HPT-002.jpg';
import PA001 from './assets/img/PA-001.jpg';
import PT001 from './assets/img/M-G052.jpg';
import MEU001 from './assets/img/M-G055.jpg';
import PRO001 from './assets/img/M-G056.jpg';
import FPB001 from './assets/img/M-G057.jpg';
import RF001 from './assets/img/M-G058.jpg';
import AFQA001 from './assets/img/M-G059.jpg';
import PCBS001 from './assets/img/M-G060.jpg';
import FU001 from './assets/img/M-G061.jpg';
import CR001 from './assets/img/M-G062.jpg';
import AAC001 from './assets/img/M-G063.jpg';
import PC002 from './assets/img/M-PC002.jpg';

import JSONpruebasconductores from './data/PruebasConductores.json';
import JSONpruebastrasformadores from './data/PruebasTransformadores.json';
import JSONpruebasmotores from './data/PruebasMotores.json';

const imageMap = {
  "HPT-002": HPT002,
  "PA-001": PA001,
  "PT-001": PT001,
  "MEU-001": MEU001,
  "PRO-001": PRO001,
  "FPB-001": FPB001,
  "RF-001": RF001,
  "AFQA-001":AFQA001,
  "PCBS-001":PCBS001,
  "FU-001" : FU001,
  "CR-001": CR001,
  "AAC-001":AAC001,
  "PC002" : PC002
};

const ConductoresData = JSONpruebasconductores.map(item => ({
  ...item,
  img: imageMap[item.img]  // Asigna la imagen correspondiente
}));
const TransformadoresData = JSONpruebastrasformadores.map(item => ({
  ...item,
  img: imageMap[item.img]  // Asigna la imagen correspondiente
}));
const MotoresData = JSONpruebasmotores.map(item => ({
  ...item,
  img: imageMap[item.img]  // Asigna la imagen correspondiente
}));

function PruebasElectricas() {
  // Establecer el primer slider y el primer elemento como activo
  const [activeIndex, setActiveIndex] = useState("0-0");

  return (
    <div className='container-PruebasElectricas'>
        <ContainerSlider 
          titulo={"Pruebas a Conductores"}
          sliderData={ConductoresData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          sliderId={0}
        />
        <ContainerSlider 
          titulo={"Pruebas a Transformadores"}
          sliderData={TransformadoresData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          sliderId={1}
        />
        <ContainerSlider 
          titulo={"Pruebas a Motores"}
          sliderData={MotoresData}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          sliderId={2}
        />
    </div>
  );
}

export default PruebasElectricas;
