import { useState, useEffect } from 'react';
import './TopSelectorbySides.css';


function TopSelectorbySides({ title, namesV }) {
  const [activeIndex, setActiveIndex] = useState(0); // Índice de opción seleccionada
  const [imageIndex, setImageIndex] = useState(0);   // Índice de imagen dentro del array (si es un array)

  useEffect(() => {
    const activeItem = namesV[activeIndex]; // El ítem activo actual

    // Solo configuramos el intervalo si el item activo tiene un array de imágenes
    if (Array.isArray(activeItem.img)) {
      const interval = setInterval(() => {
        setImageIndex((prevIndex) => (prevIndex + 1) % activeItem.img.length); // Cambiar entre imágenes
      }, 5000); // Cada 5 segundos

      return () => clearInterval(interval); // Limpiar el intervalo cuando cambie el índice activo o el componente se desmonte
    } else {
      setImageIndex(0); // Reiniciar el índice de imagen a 0 si no es un array
    }
  }, [activeIndex, namesV]);

  const activeItem = namesV[activeIndex];
  const currentImg = Array.isArray(activeItem.img) ? activeItem.img[imageIndex] : activeItem.img; // Obtener la imagen actual (única o del array)

  return (
    <div className="Container-TopSelectorbySides">
      <h2>{title}</h2>
      
      {/* Top Side */}
      <div className="Container-TSSTopSide">
        {namesV.map((item, index) => (
          <div className="TopSide-items" key={index}>
            <button
              className={`TopSideButton ${activeIndex === index ? 'active' : ''}`}
              onClick={() => setActiveIndex(index)} // Cambiar la opción activa
            >
              <h3>{item.name}</h3>
            </button>
          </div>
        ))}
      </div>

      {/* Bottom Side */}
      <div className="Container-TSSBotSide">
        <img src={currentImg} alt={activeItem.name} className="Img-TSbS" />
        <div className="TSSBotSide-text">
          <h3>{activeItem.name}</h3>
          <p dangerouslySetInnerHTML={{ __html: activeItem.description }} />
        </div>
      </div>
    </div>
  );
}

export default TopSelectorbySides;