import React, { useState, useEffect } from "react";
import video1 from './assets/mp4/M-V001.mp4';
import video2 from './assets/mp4/M-V002.mp4';
import video3 from './assets/mp4/M-V003.mp4';

// Array de videos
const videoList = [
    video1,
    video2,
    video3
];

const VideoReproductor = () => {
  const [currentVideo, setCurrentVideo] = useState(0);

  // Función para cambiar al siguiente video
  const handleVideoEnd = () => {
    setCurrentVideo((prevVideo) => (prevVideo + 1) % videoList.length);
  };

  useEffect(() => {
    const videoElement = document.getElementById("video-player");

    if (videoElement) {
      // Asigna el evento cuando el video termine
      videoElement.addEventListener("ended", handleVideoEnd);
      
      // Elimina el evento para evitar duplicados
      return () => {
        videoElement.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, [currentVideo]);

  return (
    <div>
      <video
        id="video-player"
        autoPlay
        muted
        loop={false} // Deshabilitamos el loop porque manejaremos el ciclo de videos manualmente
        style={{ display: 'block', width: '33vw' }}
        key={currentVideo} // Asegura que el video se recargue cuando cambie
      >
        <source src={videoList[currentVideo]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
export default VideoReproductor;