
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Header from './Header.js';
import MainPage from './MainPage.js';
import Footer from './Footer.js';
import Servicios from './Servicios.js'; // Agregar el nuevo componente de Servicios
import Contacto from './Contacto.js'; // Agregar el nuevo componente de Servicios
import ScrollToTop from './ScrollToTop.js';
import PruebasElectricas from './PruebasElectricas.js';
import VDF from './vdf.js';
import Experiencia from './Experiencia.js';
import SistemaPotencia from './SistemaPotencia.js';
import BombeoHorizontal from "./BombeoHorizontal.js";
import SistemasComunicacion from './SistemasComunicacion.js';
import Bec from "./Bec.js";
import Login from './Login.js';

import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="Head-Content">
         <Header />
        <div className="Content">
          <Routes>
            <Route path="/" element={<MainPage />} /> {/* Página de Inicio */}
            <Route path="/servicios" element={<Servicios />} /> {/* Página de Servicios */}
            <Route path="/contacto" element={<Contacto />} /> {/* Página de Contacto */}
            <Route path="/pruebas-electricas" element={<PruebasElectricas />}/>{/* Página de Pruebas Electricas */}
            <Route path="/vdf" element={<VDF />}/>{/* Página de Servicio a Variadores */}
            <Route path="/experiencia" element={<Experiencia />}/>{/* Página de Experiencia*/}
            <Route path="/potencia-hidraulica" element={<SistemaPotencia />}/>{/* Página de Potencia Hidraulica*/}
            <Route path="/bombeo-horizontales" element={<BombeoHorizontal />}/>{/* Página de Bombeo Horizontales*/}
            <Route path="/sistemas-comunicacion" element={<SistemasComunicacion />}/>{/* Página de Sistemas de Comunicación*/}
            <Route path="/bec" element={<Bec />}/>{/* Página de Sistemas de Comunicación*/}
            <Route path="/login" element={<Login />}/>{/* Página de login*/}
          </Routes>
        </div>
        <div className="Foot-Content">
         <Footer />
          
        </div>
      </div>
    </Router>
  );
}

export default App;
