import React, { useState, useEffect, useRef } from "react";
import './ExpandibleCards.css';

import img1 from './assets/img/VDF-001.jpg';
import img3 from './assets/img/VDF-004.jpg';
import img4 from './assets/img/VDF-003.jpg';
import img5 from './assets/img/VDF-005.jpg';
import img6 from './assets/img/VDF-006.jpg';
import img7 from './assets/img/M-G006.jpg';
import img8 from './assets/img/M-G010.jpg';
import img9 from './assets/img/M-G011.jpg';
import img10 from './assets/img/M-G013.jpg';
import img11 from './assets/img/M-G014.jpg';
import img12 from './assets/img/M-L03.jpg';
import img13 from './assets/img/BEC-001.jpg';
import img14 from './assets/img/HPT-001.jpg';
import img15 from './assets/img/HPT-002.jpg';
import img16 from './assets/img/M-G019.jpg';
import img17 from './assets/img/M-G020.jpg';
import img18 from './assets/img/M-G021.jpg';
import img19 from './assets/img/M-G022.jpg';
import img20 from './assets/img/M-G023.jpg';
import img21 from './assets/img/M-G024.jpg';
import img22 from './assets/img/M-G025.jpg';
import img23 from './assets/img/M-G026.jpg';
import img24 from './assets/img/M-G027.jpg';
import img25 from './assets/img/M-G028.jpg';
import img26 from './assets/img/M-G029.jpg';
import img27 from './assets/img/M-G030.jpg';
import img28 from './assets/img/M-G031.jpg';
import img29 from './assets/img/M-G032.jpg';
import img30 from './assets/img/M-G033.jpg';
import img31 from './assets/img/M-G034.jpg';
import img32 from './assets/img/M-G035.jpg';
import img33 from './assets/img/M-G036.jpg';
import img34 from './assets/img/M-G037.jpg';
import img35 from './assets/img/M-G038.jpg';
import img36 from './assets/img/M-G039.jpg';
import img37 from './assets/img/M-G040.jpg';
import img38 from './assets/img/M-G041.jpg';
import img39 from './assets/img/M-G042.jpg';
import img40 from './assets/img/M-G043.jpg';
import img41 from './assets/img/M-G044.jpg';
import img42 from './assets/img/M-G045.jpg';
import img43 from './assets/img/M-G046.jpg';
import img44 from './assets/img/M-G051.jpg';
import img45 from './assets/img/M-G048.jpg';
import img46 from './assets/img/M-G052.jpg';
import img47 from './assets/img/M-G053.jpg';

const items = [
    img1, img3, img4, img5, img6, img7, img8, img9, 
    img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, 
    img20, img21, img22, img23, img24, img25, img26, img27, img28, img29,
    img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, 
    img40, img41, img42, img43, img44, img45, img46, img47
]


function ExpandibleCards() {
    const [currentImages, setCurrentImages] = useState([]);
    const [activeCard, setActiveCard] = useState(null); // Tarjeta activa
    const timerRef = useRef(null); // Referencia para almacenar el timeout

    const getRandomImages = () => {
        const shuffled = [...items].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 6);
    };

    useEffect(() => {
        setCurrentImages(getRandomImages());

        const intervalId = setInterval(() => {
            setCurrentImages(getRandomImages());
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const handleMouseEnter = (index) => {
        clearTimeout(timerRef.current); // Limpia el timeout si el mouse vuelve a entrar antes de los 2 segundos
        setActiveCard(index); // Expande la tarjeta
    };

    const handleMouseLeave = () => {
        timerRef.current = setTimeout(() => {
            setActiveCard(null); // Colapsa la tarjeta después de 2 segundos
        }, 2000); // 2 segundos de retraso
    };

    return (
        <div className="container-ExpandibleCards">
            {currentImages.map((items, index) => (
                <div
                    className={`Card ${activeCard === index ? 'expanded' : ''}`} // Clase 'expanded' si está activa
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)} // Al hacer hover
                    onMouseLeave={handleMouseLeave} // Al salir del hover
                >
                    <img src={items} alt={`Imagen ${index + 1}`} className="Background" />
                    <div className="Card-Content">
                        <h3 className="title">{items.title}</h3>
                    </div>
                    <div className="Backdrop"></div>
                </div>
            ))}
        </div>
    );
}

export default ExpandibleCards;